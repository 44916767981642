import safeStringify from "fast-safe-stringify";

import type { GetStaticProps, NextPage } from "next";

import React, { useEffect } from "react";

import { ContentfulNavigation } from "../../@types/contentfulNavigation";
import { IHomepageFields } from "../../@types/generated/contentful";
import ContentSectionWrapper from "../../components/content-section-wrapper";
import CountdownBar from "../../components/countdown-bar";
import HeroTeaserHome from "../../components/hero-teaser-home";
import PageLayout from "../../components/page/layout";
import getContentfulHomepage from "../../utils/contentful/getContentfulHomepage";
import generateTitle from "../../utils/page/generateTitle";
import getJointPageProperties, {
  JointPageProperties,
} from "../../utils/page/getJointPageProperties";
import { BAS_KAST_TENANT_NAME } from "../../utils/tenant-names";

interface HomepageProps {
  homepage: IHomepageFields;
  isPreview: boolean;
  previewData: any;
  navigation: ContentfulNavigation;
  jointPageProperties: JointPageProperties;
}

const Home: NextPage<HomepageProps> = ({
  homepage,
  isPreview,
  previewData,
  navigation,
  jointPageProperties,
}) => {
  const { contentSections, seoMetadata, heroTeaser, countdownHome, tenant } =
    homepage;
  const {
    fields: { title },
  } = seoMetadata;
  const { footerProps } = jointPageProperties;
  const {
    logoDark,
    logoLight,
    titleSuffix,
    bannerVisible,
    bannerIcon,
    bannerText,
  } = jointPageProperties.siteSettings;

  const fullTitle = generateTitle(title, titleSuffix);

  useEffect(() => {
    if (isPreview && previewData?.internal !== "") {
      const previewElement = document.getElementById(previewData.internal);
      if (previewElement) {
        previewElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isPreview, previewData.internal]);

  return (
    <PageLayout
      title={fullTitle}
      footer={footerProps}
      logoHeader={logoDark}
      logoFooter={logoLight}
      bannerVisible={bannerVisible}
      bannerIcon={bannerIcon}
      bannerText={bannerText}
      metadata={seoMetadata.fields}
      navigation={navigation}
      enableFading
      tenant={tenant}
    >
      <HeroTeaserHome {...heroTeaser.fields} />

      {contentSections?.map((section, i) => (
        <ContentSectionWrapper
          key={`section-${i}`}
          section={section}
        ></ContentSectionWrapper>
      ))}
      {countdownHome && <CountdownBar {...countdownHome.fields}></CountdownBar>}
    </PageLayout>
  );
};

export default Home;

/**
 * This function gets called at build time on server-side to
 * statically pre-fetch the sections rendered on the page
 * @returns props: { homepage } - will be passed to page as props
 */
export const getStaticProps: GetStaticProps<HomepageProps> = async (
  context
) => {
  const [homepage, jointPageProperties] = await Promise.all([
    getContentfulHomepage({
      isPreview: context.preview || false,
      previewData: context.previewData,
      slug: `${BAS_KAST_TENANT_NAME}/start`,
    }),
    getJointPageProperties(context, BAS_KAST_TENANT_NAME),
  ]);

  if (!homepage) {
    return { notFound: true };
  }

  const propsData = safeStringify({
    homepage: homepage.fields,
    isPreview: context.preview || false,
    previewData: context.previewData || {
      env: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
      internal: "",
    },
    navigation: jointPageProperties.navigation,
    jointPageProperties,
    theme: BAS_KAST_TENANT_NAME,
  });

  const props = JSON.parse(propsData);

  // pass page data to the page via props
  return {
    props,
  };
};
